<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">实验报告</h3>
    <div class="detail">
      <h4 class="title">上传实验报告</h4>
      <el-upload
          class="upload-demo"
          drag
          :show-file-list="false"
          :action="upLoadUrl"
          :headers="headers"
          :data="uploadVto"
          :on-change="handleChange"
          :on-success="handleAvatarSuccess"
          :auto-upload="false"
          ref="upload"
          multiple>
        <i class="el-icon-upload"></i>
        <p>上传实验报告</p>
        <el-button type="primary" size="small">点击上传</el-button>
      </el-upload>
      <h4 class="title">已上传文件</h4>
      <el-table
          :data="fileList"
          border
          class="tableMember"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          style="width: 100%;font-size:14px;color:#333">
        <el-table-column label="文件名称" show-overflow-tooltip prop="name">
        </el-table-column>
        <el-table-column label="上传人" show-overflow-tooltip prop="nickname">
        </el-table-column>
        <el-table-column label="上传时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDay }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div class="addColor">
              <el-button type="text" @click="downloadR(scope.row)">下载</el-button>
              <el-button type="text" @click="deleteA(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <!--    弹框确认是否加密-->
    <el-dialog :close-on-click-modal="false"
               title="是否加密文件？"
               class="flex dialogForm "
               top="0"
               :visible.sync="dialog"
               width="450px">
      <el-form ref="formAction" size="small" :model="formTip" class="addForm" label-suffix=":"
               label-width="90px">
        <el-form-item label="加密文件">
          <el-radio-group v-model="formTip.isEncryt" class="radioGroup">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain type="primary" @click="dialog=false">取消</el-button>
        <el-button size="small" type="primary" @click="submitForm">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "report",
  props: ['id'],
  data() {
    return {
      fileList: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      // 上传附件
      upLoadUrl: this.$local.baseURL() + '/subject/report',
      uploadVto: {
        type: 1,
        subId: this.id,
        isEncryt: 0
      },
      isConfirm: true,
      headers: {
        "Authentication": this.$store.state.account.token
      },
      dialog: false,
      formTip: {
        isEncryt: 0
      }
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  methods: {
    async handleChange(files, fileList) {
      if (!this.isConfirm) {
        this.isConfirm = true
        return;
      }
      this.dialog = true
    },
    handleAvatarSuccess() {
      this.$message.success("上传成功")
      this.$nextTick(() => {
        this.getData()
      })
    },
    submitForm() {
      this.uploadVto.isEncryt = this.formTip.isEncryt
      this.isConfirm = false;
      this.dialog = false
      this.$refs.upload.submit();
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    getData() {
      let that = this
      let params = {
        pageSize: that.page.size,
        pageNum: that.page.current,
        type: 1
      }
      this.$get('/subject/report/byPage/' + this.id, params).then(res => {
        if (res.status == 200) {
          /*res.data.records.forEach((item, index) => {
            if ((item.isEncryt == 1 && this.user.userId != item.createBy)) {
              res.data.records.splice(index, 1)
            }
          })*/
          this.fileList = res.data.records
        }
      })
    },
    // 删除
    deleteA(id) {
      this.$confirm('是否删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$put("/subject/report/" + id).then(res => {
          if (res.status == 200) {
            this.$message.success("删除成功")
            this.$nextTick(() => {
              this.getData()
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    // 下载
    downloadR(row) {
      this.$download('/subject/report/' + row.id, {isEncryt: row.isEncryt},row.name).then(res => {
        this.$message.success("下载成功")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
